import React from 'react';
import Sidebartags from './Sidebartags';

const Disclaimer = () => {
  
  return (
<div className="container-fluid topspacer">
  <div className="container">
    <div className="row">
      <div className="col-md-8 py-5">
      <div class="entry-content clearfix">
<h2> Disclaimer</h2>
<p>Last updated: 01/01/2024</p>
<p>The information provided by Files Genius ("we," "us," or "our") on [website URL] is for general informational purposes only. All information on the website is provided in good faith; however, we make no representation or warranty of any kind, express or implied, regarding the accuracy, adequacy, validity, reliability, availability, or completeness of any information on the site.</p>

<p>Under no circumstance shall we have any liability to you for any loss or damage of any kind incurred as a result of the use of the site or reliance on any information provided on the site. Your use of the site and your reliance on any information on the site is solely at your own risk.</p>

<p>The site may contain (or you may be sent through the site) links to other websites or content belonging to or originating from third parties or links to websites and features. Such external links are not investigated, monitored, or checked for accuracy, adequacy, validity, reliability, availability, or completeness by us.</p>

<p>We do not warrant, endorse, guarantee, or assume responsibility for the accuracy or reliability of any information offered by third-party websites linked through the site or any website or feature linked in any banner or other advertising. We will not be a party to or in any way be responsible for monitoring any transaction between you and third-party providers of products or services.</p>

<p>External Links Disclaimer:</p>
<p>The site may contain (or you may be sent through the site) links to other websites or content belonging to or originating from third parties or links to websites and features. Such external links are not investigated, monitored, or checked for accuracy, adequacy, validity, reliability, availability, or completeness by us.</p>

<p>Professional Disclaimer:</p>
<p>The site cannot and does not contain professional advice. The information is provided for general informational and educational purposes only and is not a substitute for professional advice. Accordingly, before taking any actions based upon such information, we encourage you to consult with the appropriate professionals. We do not provide any kind of professional advice.</p>

<p>Views and Opinions Disclaimer:</p>
<p>The views and opinions expressed on the site are those of the authors and do not necessarily reflect the official policy or position of any other author, agency, organization, employer, or company, including Files Genius.</p>

<p>Affiliate Disclaimer:</p>
<p>The site may contain links to affiliate websites, and we may receive an affiliate commission for any purchases or actions made by you on the affiliate websites using such links.</p>

<p>Changes and Amendments:</p>
<p>We reserve the right to modify this Disclaimer or its terms relating to the site and services at any time, effective upon posting of an updated version of this Disclaimer on the site. When we do, we will revise the updated date at the bottom of this page. Continued use of the site after any such changes shall constitute your consent to such changes.</p>

<p>Acceptance of This Disclaimer:</p>
<p>You acknowledge that you have read this Disclaimer and agree to all its terms and conditions. By accessing the site, you agree to be bound by this Disclaimer. If you do not agree to abide by the terms of this Disclaimer, you are not authorized to use or access the site.</p>

<p>Contact Us:</p>
<p>If you have any questions or concerns regarding this Disclaimer, please contact us at info@filesgenius.com.</p>


      </div>
      </div>
      <div className="col-md-4 py-5">
        <Sidebartags />
      <div className="submitbox mb-5">
        <h4>Submit Your Repository and Shine in the Spotlight!</h4>
        <p>Showcase your project to a global audience by submitting it to our platform. Share your innovation, connect with like-minded developers, and let your code shine in the spotlight.</p>
        <div className="header-cta m-0"><a href="/submit"><div className="btn btn-primary m-0">Featured Repo</div></a></div>
        </div>
        <h5>Quick Links</h5>
        <ul className="sidemenu">
        <li><a href="/home">Home</a></li>
        <li><a href="/contact">Contact</a></li>
        <li><a href="/privacy">Privacy Policy</a></li>
        <li><a href="/Disclaimer">Disclaimer</a></li>  
        </ul>
        </div>
    </div>
  </div>
</div>
)
}

export default Disclaimer;
