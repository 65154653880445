// NotFound.js
import React from 'react';

const NotFound = () => {
  return (
    <div className="topspacer container-fluid">
    <div className="row py-5 my-5">
      <h1>404 - Not Found</h1>
      <p>Sorry, the page you are looking for might be in another castle.</p>
    </div>
    </div>

  );
};

export default NotFound;
