import React from 'react';
import Sidebartags from './Sidebartags';

const TOS = () => {
  
  return (
<div className="container-fluid topspacer">
  <div className="container">
    <div className="row">
      <div className="col-md-8 py-5">
      <div class="entry-content clearfix">
<h2> Terms of Service for Files Genius</h2>
<p>1. Acceptance of Terms<br />
   - By using the Files Genius service ("Service"), you agree to comply with and be bound by these Terms of Service. If you do not agree to these terms, please do not use the Service.
</p>
<p>2. Description of Service<br />
   - Files Genius provides a platform for users to discover and search for GIT repositories. The Service enables users to access information about various repositories hosted on GitHub.
</p>
<p>3. Access to Information<br />
   - Users can access information about GIT repositories without any restrictions. However, certain features, such as referral or opener links, may be restricted until a repository is featured by contacting Files Genius via email at info@filesgenius.com.</p>

<p>4. Repository Features<br />
   - To have a GIT repository featured on Files Genius with additional benefits, users must contact Files Genius via email at info@filesgenius.com. Features may include but are not limited to referral links, opener links, and enhanced visibility.</p>

<p>5. User Conduct<br />
   - Users are expected to use the Service responsibly and in compliance with applicable laws and regulations. Any misuse or unauthorized access to the Service is strictly prohibited.</p>

<p>6. Intellectual Property<br />
   - All content provided by Files Genius, including but not limited to text, graphics, logos, and images, is the property of Files Genius or its licensors and is protected by copyright laws.</p>

<p>7. Limitation of Liability<br />
   - Files Genius is not responsible for the accuracy, completeness, or legality of information provided in GIT repositories. Users access repositories at their own risk. Files Genius is not liable for any damages arising from the use of the Service.</p>

<p>8. Changes to Terms<br />
   - Files Genius reserves the right to update or modify these Terms of Service at any time. Users are encouraged to check this page regularly for any changes.</p>

<p>9. Contact Information<br />
   - For any questions or concerns regarding these Terms of Service, please contact Files Genius at info@filesgenius.com.</p>

By using the Files Genius Service, you acknowledge that you have read, understood, and agree to these Terms of Service.
      </div>
      </div>
      <div className="col-md-4 py-5">
      <Sidebartags />
      <div className="submitbox mb-5">
        <h4>Submit Your Repository and Shine in the Spotlight!</h4>
        <p>Showcase your project to a global audience by submitting it to our platform. Share your innovation, connect with like-minded developers, and let your code shine in the spotlight.</p>
        <div className="header-cta m-0"><a href="/submit"><div className="btn btn-primary m-0">Featured Repo</div></a></div>
        </div>
        <h5>Quick Links</h5>
        <ul className="sidemenu">
        <li><a href="/home">Home</a></li>
        <li><a href="/contact">Contact</a></li>
        <li><a href="/privacy">Privacy Policy</a></li>
        <li><a href="/Disclaimer">Disclaimer</a></li>  
        </ul>
        </div>
    </div>
  </div>
</div>
)
}

export default TOS;
