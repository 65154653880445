import React from 'react';
import Submitimg from './assets/submit.png';


const Submit = () => {
    return (
 
  <div className="container-fluid submit py-5 mt-5">
  <div className="container">
    <div className="row align-items-center">
      <div className="col-md-5"><img loading="lazy" src={Submitimg} alt="Files Genius" /></div>
      <div className="col-md-7"><h4>Contact us today to get started on this exciting journey together!</h4>
      <h3 className="mb-4">Craft a Captivating Advertisement and Showcase Your Vision</h3>  
          <p>Connect with us to submit your project, and let's collaborate to bring your creation into the spotlight</p>
          <div className="header-cta m-0">
            <a href="/submit"><div className="btn btn-primary m-0">Submit a Repo</div></a>
            <a href="https://www.buymeacoffee.com/brandlesssg" target='_blank' className="btn button btn-info ms-2">Buy me Coffee</a>
          </div>
</div>
    </div>
  </div>
  </div>
)}

export default Submit;
