import React, { useState }from 'react';
import 'bootstrap/dist/css/bootstrap.min.css'; 

const Header = () => {
  const [isNavbarCollapsed, setIsNavbarCollapsed] = useState(true);

  const handleMobileToggle = () => {
    setIsNavbarCollapsed((prev) => !prev);
  };
  
  return (
    <nav id="pr-nav" className="primary-menu navbar navbar-expand-lg navbar-dark">
    <div className="container-fluid primary-menu-inner px-0">
        <div className="top-wrap"><a className="custom-logo-link" href="https://www.filesgenius.com/"><h5 className="m-0"><img loading="lazy" src="https://www.filesgenius.com/logo512.png" width={100} height={50} alt="files genius" /></h5></a><button id="mobile-toggle" className="navbar-toggler animate-button collapsed" type="button" data-toggle="collapse" data-target="#navbarColor01" aria-controls="navbarColor01" aria-expanded="false" aria-label="Toggle navigation" onClick={handleMobileToggle}><span id="m-tgl-icon" className="animated-icon1"><span></span><span></span></span></button>
        </div>
        <div className={`navbarColor01 collapse collapse navbar-collapse justify-content-end ${isNavbarCollapsed ? '' : 'show'}`}>
            <ul id="primary-menu" className="navbar-nav pl-3">
            <li  id="menu-item-4835" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children dropdown menu-item-4835 nav-item"><a href="/" className="nav-link">Home</a></li>
            <li  id="menu-item-4835" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children dropdown menu-item-4835 nav-item"><a href="/genie/index.html" className="nav-link">Image Genie</a></li>

    <li  id="menu-item-12457" className="menu-item menu-item-type-custom menu-item-object-custom current-menu-ancestor current-menu-parent menu-item-has-children dropdown active menu-item-12457 nav-item"><a href="#" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" className="dropdown-toggle nav-link" id="menu-item-dropdown-12457"><span>Languages</span></a>
            <ul className="dropdown-menu">
	<li className="menu-item menu-item-type-post_type menu-item-object-page nav-item"><a href="/language/python" className="dropdown-item"><span>Python</span></a></li>
	<li className="menu-item menu-item-type-post_type menu-item-object-page nav-item"><a href="/language/javascript" className="dropdown-item"><span>JavaScript</span></a></li>
	<li className="menu-item menu-item-type-post_type menu-item-object-page nav-item"><a href="/language/java" className="dropdown-item"><span>Java</span></a></li>
	<li className="menu-item menu-item-type-post_type menu-item-object-page nav-item"><a href="/language/go" className="dropdown-item"><span>Go</span></a></li>
	<li className="menu-item menu-item-type-post_type menu-item-object-page nav-item"><a href="/language/cpp" className="dropdown-item"><span>C++</span></a></li>
	<li className="menu-item menu-item-type-post_type menu-item-object-page nav-item"><a href="/language/typescript" className="dropdown-item"><span>TypeScript</span></a></li>
	<li className="menu-item menu-item-type-post_type menu-item-object-page nav-item"><a href="/language/php" className="dropdown-item"><span>PHP</span></a></li>
	<li className="menu-item menu-item-type-post_type menu-item-object-page nav-item"><a href="/language/c" className="dropdown-item"><span>C</span></a></li>
	<li className="menu-item menu-item-type-post_type menu-item-object-page nav-item"><a href="/language/ruby" className="dropdown-item"><span>Ruby</span></a></li>
	<li className="menu-item menu-item-type-post_type menu-item-object-page nav-item"><a href="/language/csharp" className="dropdown-item"><span>C#</span></a></li>	
  </ul></li>
  
  <li  id="menu-item-4835" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children dropdown menu-item-4835 nav-item"><a href="/contact" className="nav-link">Contact Us</a></li>
</ul>
<div className="header-cta"><a href="/submit"><div className="d-inline-block elementor-button-link elementor-button elementor-size-md">Submit a Repo</div></a></div>
</div>

    </div>
</nav>

  );
};

export default Header;
