import React from 'react';
import { BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import ScrollToTop from './ScrollToTop'; // Import the ScrollToTop component



 //Pages
import Main from './Main';
import SubmitRepoPage from './SubmitRepoPage';
import ContactPage from './ContactPage';
import TermsOfServicePage from './TermsOfServicePage';
import PrivacyPolicyPage from './PrivacyPolicyPage';
import Disclaimer from './Disclaimer';
import LanguageResultsPage from './LanguageResultsPage';
import NotFound from './NotFound'; 
import RepoDetails from './Repodetails';

import Header from './Header';
import Footer from './Footer'; 
import Submit from './Submit';

const App = () => {

 return(
  <div>
      <Header />
       <Router>
       <ScrollToTop /> {/* Include ScrollToTop as a sibling to the Switch */}
        <Routes>
        <Route path="/" element={<Main />} />
        <Route path="https://filesgenius.com/genie/index.html" />
        <Route path="/contact" element={<ContactPage />} />
       <Route path="/submit" element={<SubmitRepoPage />} />
       <Route path="/terms" element={<TermsOfServicePage />} />
       <Route path="/privacy" element={<PrivacyPolicyPage />} />
       <Route path="/disclaimer" element={<Disclaimer />} />
       <Route path="/repo/:owner/:repo" element={<RepoDetails />} />
      <Route path="/language/:language" element={<LanguageResultsPage />} />
      <Route element={<NotFound />} />
      </Routes>
      </Router>

      <Submit />
      <Footer />
    </div>

);
};

export default App;
