import React from 'react';
import Discussimg from './assets/discuss.png';
 


const Discuss = () => {
    return (
      <div className="container-fluid discuss">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-6">
              <h4>Explore GitHub Projects</h4>
              <h1 className="mb-4">Unleashing Tools and Solutions for Every Endeavor</h1>
              <p>From coding wizards to productivity gems, find the tools that empower your projects. Navigate through a diverse array of solutions, each designed to make your tasks seamless
              </p>
            </div>
            <div className="col-md-6 text-end">
              <img loading="lazy" src={Discussimg} alt="Files Genius" className="heroimg" />
            </div>
          </div>
        </div>
      </div>
    );
  };
  
  export default Discuss;
  