import React, { useState, useEffect } from 'react';
import axios from 'axios';
import cloneimg from './assets/clone.png';
import visitimg from './assets/visit.webp';
import verified from './assets/verified.png'
import {Link} from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTwitter, faFacebook, faLinkedin, faWhatsapp, faPinterest } from '@fortawesome/free-brands-svg-icons';



const FeaturedRepo = ({ ownerNames }) => {
  const [repoData, setRepoData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchRandomRepo = async () => {
      try {
        setLoading(true);

        // Choose a random owner from the provided array
        const randomOwner = ownerNames[Math.floor(Math.random() * ownerNames.length)];

        // Fetch a random repository for the selected owner
        const response = await axios.get(`https://api.github.com/users/${randomOwner}/repos`);

        if (response.data.length === 0) {
          setError('');
          return;
        }

        // Get a random index from the response array
        const randomIndex = Math.floor(Math.random() * response.data.length);

        // Get the repository at the random index
        const randomRepo = response.data[randomIndex];

        setRepoData(randomRepo);
      } catch (error) {
        setError('');
      } finally {
        setLoading(false);
      }
    };

    fetchRandomRepo();
  }, [ownerNames]);
  
  if (error) {
    return <p>Error: {error}</p>;
  }

  if (!repoData) {
    return <p>&nbsp;</p>;
  }

  return (
    <div className="container-fluid py-5 my-5 fullfeatured">
        <div className="container">
            <div className="row align-items-center justify-content-center">
            <div className="col-md-12 py-5">
        <h3 className="text-center text-white">Explore the GitHub Gems Highly Forked Repositories</h3>
        <p className="text-center mb-5  text-white">Dive into the world of open-source collaboration, innovation, and community-driven projects.</p>
        </div>
      <div className="col-md-8 mb-5">
      <div className="featuredrepo">
      <div className="txtbox">
      <h2 title="Featured GIT Repository">
      <Link to={`/repo/${repoData.owner.login}/${repoData.name}`}>{repoData.name}</Link><img src={verified} alt="Featued Repo" /></h2>
      <h3><svg class="svg-icon" aria-hidden="true" role="img" focusable="false" xmlns="http://www.w3.org/2000/svg" width="18" height="20" viewBox="0 0 18 20"><path fill="" d="M18,19 C18,19.5522847 17.5522847,20 17,20 C16.4477153,20 16,19.5522847 16,19 L16,17 C16,15.3431458 14.6568542,14 13,14 L5,14 C3.34314575,14 2,15.3431458 2,17 L2,19 C2,19.5522847 1.55228475,20 1,20 C0.44771525,20 0,19.5522847 0,19 L0,17 C0,14.2385763 2.23857625,12 5,12 L13,12 C15.7614237,12 18,14.2385763 18,17 L18,19 Z M9,10 C6.23857625,10 4,7.76142375 4,5 C4,2.23857625 6.23857625,0 9,0 C11.7614237,0 14,2.23857625 14,5 C14,7.76142375 11.7614237,10 9,10 Z M9,8 C10.6568542,8 12,6.65685425 12,5 C12,3.34314575 10.6568542,2 9,2 C7.34314575,2 6,3.34314575 6,5 C6,6.65685425 7.34314575,8 9,8 Z"></path></svg> {repoData.owner.login}</h3>
      <p>{repoData.description}</p>
      </div>
      <div className="txtbox2">
      <a href={repoData.clone_url} target="_blank"><img loading="lazy" src={cloneimg} alt="Clone" title="Clone Project" className="me-2" /></a>
      <a href={repoData.html_url} target="_blank" rel="noopener noreferrer"><img loading="lazy" src={visitimg} alt="Visit" title="Visit Project" /></a>
    </div>
      <span className="visitbtn">Forks: {repoData.forks_count}<br/>Watchers: {repoData.watchers}<br/>Stars: {repoData.stargazers_count}</span>
      <div className="txtbox3">
              <a href={`https://www.facebook.com/sharer/sharer.php?u=${repoData.html_url}&p[title]=referral from https://www.filesgenius.com`} target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faFacebook} /></a>
                      <a href={`http://twitter.com/share?text=Check out this GitHub repo: ${repoData.html_url} referral from https://www.filesgenius.com`} target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faTwitter} /></a>
                      <a href={`http://www.linkedin.com/shareArticle?mini=true&url=${repoData.html_url}&summary=referral from https://www.filesgenius.com`} target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faLinkedin} /></a>
                      <a href={`https://api.whatsapp.com/send/?text=Check out this GitHub repo: ${repoData.html_url} referral from https://www.filesgenius.com`} target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faWhatsapp} /></a>
                      <a href={`http://pinterest.com/pin/create/button/?url=Check out this GitHub repo: ${repoData.html_url} referral from https://www.filesgenius.com`} target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faPinterest} /></a>
              </div>
    </div>
    </div>
    <div className="col-md-4">
        <div className="submitbox">
        <h4>Submit Your Repository and Shine in the Spotlight!</h4>
        <p>Showcase your project to a global audience by submitting it to our platform. Share your innovation, connect with like-minded developers, and let your code shine in the spotlight.</p>
        <div className="header-cta m-0"><a href="/submit"><div className="btn btn-primary m-0">Submit a Repo</div></a></div>
        </div>


    </div>




    </div>
    </div>
    </div>
  );
};

export default FeaturedRepo;
