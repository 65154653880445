import React, { useState } from 'react';
import axios from 'axios';

import Hero from './Hero';
import Discuss from './Discuss';
import Mostdownloaded from './Mostdownloadded';
import ResultsDisplay from './ResultsDisplay';
import FeaturedRepo from './FeaturedRepo';

const Main = () => {
 
  const [inputValue, setInputValue] = useState(''); // New state for input value
const [searchQuery, setSearchQuery] = useState('');
const [page, setPage] = useState(1);
const [results, setResults] = useState([]);
const [loading, setLoading] = useState(false);
const [error, setError] = useState(null);




const formatStars = (stars) => {
  if (stars >= 1000000) {
    return (stars / 1000000).toFixed(1) + 'M';
  } else if (stars >= 1000) {
    return (stars / 1000).toFixed(1) + 'K';
  } else {
    return stars.toString();
  }
};


const handleSearch = async () => {
  
  try {
    setLoading(true);
    setError(null);

    setResults([]);
    setPage(1);

    if (searchQuery.trim() !== '') {
      const response = await axios.get(`https://api.github.com/search/repositories?q=${searchQuery}&s=stars,&page=${page}`);
      const newResults = response.data.items.map((result) => ({
        id: result.id,
        name: result.name,
        description: result.description,
        url: result.html_url,
        total_count: response.data.total_count,
        full_name: result.full_name,
        private: result.private,
        login: result.owner.login,
        avatar_url: result.owner.avatar_url,
        user_url: result.owner.url,
        html_url: result.html_url,
        followers_url: result.owner.followers_url,
        subscriptions_url: result.owner.subscriptions_url,
        organizations_url: result.owner.organizations_url,
        repos_url: result.owner.repos_url,
        type: result.owner.type,
        downloads_url: result.downloads_url,
        created_at: result.created_at,
        updated_at: result.updated_at,
        pushed_at: result.pushed_at,
        git_url: result.git_url,
        ssh_url: result.ssh_url,
        clone_url: result.clone_url,
        homepage: result.homepage,
        size: result.size,
        language: result.language,
        forks_count: result.forks_count,
        stars: formatStars(result.stargazers_count),
        license: result.license ? result.license.name : null,
        allow_forking: result.allow_forking,
        visibility: result.visibility,
        watchers: result.watchers,
        default_branch: result.default_branch,
        score: result.score,
      }));

      setResults((prevResults) => (page === 1 ? newResults : [...prevResults, ...newResults]));
      setPage((prevPage) => prevPage + 1);
    } else {
      setResults([]);
      setPage(1);
    }
  } catch (error) {
    console.error('Error fetching search results:', error);
    setError('Error fetching search results');
  } finally {
    setLoading(false);
  }
};

const handleInputChange = (e) => {
  setInputValue(e.target.value);
};

const loadMore = async () => {
  try {
    setLoading(true);
    const nextPage = page + 2;

    const response = await axios.get(`https://api.github.com/search/repositories?q=${searchQuery}&page=${nextPage}`);
    const newResults = response.data.items.map((result) => ({
      id: result.id,
      name: result.name,
      description: result.description,
      url: result.html_url,
      total_count: response.data.total_count,
      full_name: result.full_name,
      private: result.private,
      login: result.owner.login,
      avatar_url: result.owner.avatar_url,
      user_url: result.owner.url,
      html_url: result.html_url,
      followers_url: result.owner.followers_url,
      subscriptions_url: result.owner.subscriptions_url,
      organizations_url: result.owner.organizations_url,
      repos_url: result.owner.repos_url,
      type: result.owner.type,
      downloads_url: result.downloads_url,
      created_at: result.created_at,
      updated_at: result.updated_at,
      pushed_at: result.pushed_at,
      git_url: result.git_url,
      ssh_url: result.ssh_url,
      clone_url: result.clone_url,
      homepage: result.homepage,
      size: result.size,
      language: result.language,
      forks_count: result.forks_count,
      stars: formatStars(result.stargazers_count),
      license: result.license ? result.license.name : null,
      allow_forking: result.allow_forking,
      visibility: result.visibility,
      watchers: result.watchers,
      default_branch: result.default_branch,
      score: result.score,
    }));

    setResults((prevResults) => [...prevResults, ...newResults]);
    setPage(nextPage);
   
  } catch (error) {
    console.error('Error fetching more search results:', error);
    //setError('Error fetching more search results');
  } finally {
    setLoading(false);
  }
}
return(
<div>
<Hero searchQuery={searchQuery} setSearchQuery={setSearchQuery} handleSearch={handleSearch} />
<FeaturedRepo ownerNames={['dangelo', 'tillmann', 'farrukkhsheikkh', 'csehammad']} />
<ResultsDisplay results={results} loading={loading} error={error} loadMore={loadMore} searchQuery={searchQuery}  inputValue={inputValue} handleInputChange={handleInputChange}  />
<Mostdownloaded />
<Discuss />
</div>
)
}
export default Main;
