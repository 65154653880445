import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Octokit } from '@octokit/core';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import DOMPurify from 'dompurify';
import Sidebartags from './Sidebartags';


const octokit = new Octokit();

const RepoDetails = () => {
  const { owner, repo, forks } = useParams();
  const [readmeContent, setReadmeContent] = useState('');

  useEffect(() => {
    // Fetch the README content for the selected repository
    const fetchReadmeContent = async () => {
      try {
        const response = await octokit.request('GET /repos/{owner}/{repo}/contents/{path}', {
          owner,
          repo,
          forks,
          path: 'README.md',  // Adjust the file path as needed
          headers: {
            'X-GitHub-Api-Version': '2022-11-28'
          }
        });


        const readmeBase64 = response.data.content;
        const decodedReadme = decodeURIComponent(
          atob(readmeBase64).split('').map(c => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)).join('')
        );

        // Use DOMPurify to sanitize the HTML content
        const sanitizedContent = DOMPurify.sanitize(decodedReadme);

        const baseUrl = `https://raw.githubusercontent.com/${owner}/${repo}/master/`; // Adjust 'main' branch if needed
const absoluteContent = sanitizedContent.replace(/src="([^"]+)"/g, (match, src) => {
  // Check if the src already starts with https://
  if (src.startsWith('https://')) {
    return match; // Keep the original src if it's an external URL
  } else {
    return `src="${baseUrl}${src}"`; // Add the baseURL for relative paths
  }
});

            

        setReadmeContent(absoluteContent);
      } catch (error) {
        console.error('Error fetching README content:', error);
      }
    };

    fetchReadmeContent();
  }, [owner, repo, forks]);
  return (
      <div className="container topspacer readmedata">
      <div className="row my-5">
        <div className="col-md-8">
          <h2 className="mb-4 text-start">{repo}, <small>{owner}</small></h2>
          <div className="bg-white p-5 contentbox">
          {readmeContent ? (
            <ReactMarkdown rehypePlugins={[rehypeRaw]} children={readmeContent} />
            ) : (
            <p className="text-center">"We're currently experiencing a high volume of users. Please consider waiting or refreshing in a little while. Thank you for your patience!"</p>
          )}
          </div>

        </div>

        <div className="col-md-4 py-5">
          <Sidebartags />
      <div className="submitbox mb-5">
        <h4>Submit Your Repository and Shine in the Spotlight!</h4>
        <p>Showcase your project to a global audience by submitting it to our platform. Share your innovation, connect with like-minded developers, and let your code shine in the spotlight.</p>
        <div className="header-cta m-0"><a href="/submit"><div className="btn btn-primary m-0">Featured Repo</div></a></div>
        </div>
        <h5>Quick Links</h5>
        <ul className="sidemenu">
        <li><a href="/home">Home</a></li>
        <li><a href="/contact">Contact</a></li>
        <li><a href="/privacy">Privacy Policy</a></li>
        <li><a href="/Disclaimer">Disclaimer</a></li>  
        </ul>
        </div>
      </div>
    </div>
  );
};

export default RepoDetails;
