import React, { useEffect } from 'react';
import Heroimg from './assets/hero.png';
import Searchico from './assets/search.svg';



const Hero = ({ searchQuery, setSearchQuery, handleSearch }) => {

const handleScroll = () => {
  const scrollY = window.scrollY;
  const heroSearch = document.querySelector('.herosearch');

  if (scrollY > 300) {
    heroSearch.classList.add('sticky');
  } else {
    heroSearch.classList.remove('sticky');
  }
};

const scrollToSearchResult = () => {
  const element = document.getElementById('searchresult');
    element.scrollIntoView({ behavior: 'smooth' });
}; 




// Add event listeners in useEffect
useEffect(() => {
  window.addEventListener('scroll', handleScroll);

  return () => {
    window.removeEventListener('scroll', handleScroll);
  };
}, []);
 
    return (
      <div className="container-fluid hero topspacer">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-6">
              <img loading="lazy" src={Heroimg} alt="FilesGenius.com" className="heroimg" />
            </div>
            <div className="col-md-6">
              <h4>The Hub for Unveiling Every Detail</h4>
              <h1 className="mb-4">Uncover Every Detail, Every Repository, in One Click</h1>
  
              {/* Search Form */}
              <form className="herosearch" onSubmit={(e) => { e.preventDefault(); handleSearch(); handleScroll(); scrollToSearchResult(); }}>
  <input
    type="text"
    value={searchQuery}
    onChange={(e) => setSearchQuery(e.target.value)}
    placeholder="Search GIT Repo "
    required="required"
    className="form-control"
  />
  <button type="submit" id="scrollButton"><img loading="lazy" src={Searchico} alt="FilesGenius.com" /></button>
</form>

  
              <p>
                Explore, Organize, and gain instant access to a wealth of information, allowing them to explore, analyze, and understand the intricacies of any GIT repository seamlessly.
              </p>
              <div className="header-cta m-0 ">If you wish to featured your Repo <a href="/submit" id><div className="btn btn-primary mx-0 my-4">Submit a Repo</div></a></div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  
  export default Hero;
  