import React from 'react';
import Sidebartags from './Sidebartags';

const Privacy = () => {
  
  return (
<div className="container-fluid topspacer">
  <div className="container">
    <div className="row">
      <div className="col-md-8 py-5">
      <div class="entry-content clearfix">
<h2> Privacy Policy</h2>

<p>This Privacy Policy describes how Files Genius ("we," "us," or "our") collects, uses, and shares your personal information when you use our website and services (the "Service").</p>

<p>Information We Collect:</p>

<p>Personal Information: We may collect personal information such as your name, email address, and other details you provide voluntarily when contacting us.</p>

<p>Usage Data: We collect information about how you use our website, including your IP address, browser type, and browsing behavior.</p>

<p>How We Use Your Information:</p>

<p>We use your personal information to respond to your inquiries, provide customer support, and communicate with you.</p>
<p>We may use aggregated and anonymized data for statistical and analytical purposes.</p>
Cookies and Similar Technologies:

<p>We use cookies and similar tracking technologies to enhance your experience and analyze website usage. You can set your browser to refuse cookies, but this may affect your ability to access certain features.</p>
<p>Third-Party Services:</p>

<p>We may use third-party services to analyze website usage, such as Google Analytics. These third parties may use cookies and similar technologies.</p>
<p>Information Sharing:</p>

<p>We do not sell, trade, or otherwise transfer your personal information to third parties without your consent, except for trusted third parties who assist us in operating our website and services.</p>
<p>Security:</p>

<p>We implement security measures to protect your personal information. However, no method of transmission over the internet or electronic storage is entirely secure.</p>
<p>Children's Privacy:</p>

<p>The Service is not intended for individuals under the age of 13. We do not knowingly collect personal information from children.</p>
<p>Changes to This Privacy Policy:</p>

<p>We may update this Privacy Policy to reflect changes in our practices or for other operational, legal, or regulatory reasons. We encourage you to review this page periodically.</p>
<p>Your Consent:</p>

<p>By using our Service, you consent to our Privacy Policy.</p>
<p>Contact Information:</p>

<p>For questions about this Privacy Policy, please contact us at info@filesgenius.com.</p>
<p>This Privacy Policy was last updated on 01-Jan-2024.</p>


      </div>
      </div>
      <div className="col-md-4 py-5">
        <Sidebartags />
      <div className="submitbox mb-5">
        <h4>Submit Your Repository and Shine in the Spotlight!</h4>
        <p>Showcase your project to a global audience by submitting it to our platform. Share your innovation, connect with like-minded developers, and let your code shine in the spotlight.</p>
        <div className="header-cta m-0"><a href="/submit"><div className="btn btn-primary m-0">Featured Repo</div></a></div>
        </div>
        <h5>Quick Links</h5>
        <ul className="sidemenu">
        <li><a href="/home">Home</a></li>
        <li><a href="/contact">Contact</a></li>
        <li><a href="/privacy">Privacy Policy</a></li>
        <li><a href="/Disclaimer">Disclaimer</a></li>  
        </ul>
        </div>
    </div>
  </div>
</div>
)
}

export default Privacy;
