import React, { useRef, useState } from 'react';
import contactimg from './assets/contact.png';
import emailjs from '@emailjs/browser';


const ContactForm = () => {
  const form = useRef();
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);


  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_fsldbwu', 'template_zu22trn', form.current, 'Skuoq9yHt35HWdnHN')
      .then((result) => {
          
        const successMessage = document.querySelector('.success-message');
        successMessage.classList.remove('d-none');           
      }, (error) => {
      });

      // Hide success message after 3 seconds
      setTimeout(() => {
        setShowSuccessMessage(false);
      }, 3000);
  }

 


      
  return (

    <div className="container topspacer">
      <div className="row">
      <div className="col-md-12">
        <h3 className="text-center">Contact Us</h3>
        <p className="text-center mb-5">Feel free to reach out to us using the provided contact form or find our contact details below..</p>
        </div>
      <div className="col-md-6 order-lg-2">
        <img loading="lazy" src={contactimg} alt="contact page file genius" />
      </div>
        <div className="col-md-6">
    <form ref={form} onSubmit={sendEmail} className={`ctform ${showSuccessMessage ? 'hidden' : ''}`}>
      <h4>Write us a message</h4>
      <label>
        Name:
        <input type="text" name="user_name" />
      </label>
    
      <label>
        Email:
        <input type="email" name="user_email"required="required" />
      </label>
      <br />
      <label>
        Message:
        <textarea name="message" />
      </label>
      <br />
      <button type="submit" className="btn btn-primary">SEND</button>
      <div className="success-message d-none">
              <br /><br />
              Thank you for your message! A representative will contact you shortly.
            </div>
    </form>
</div>


</div></div>

  );
}

export default ContactForm;
