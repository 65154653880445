import React from 'react';

const Sidebartags = () => {

    return(
        <><h4>Tags:</h4>
        <ul className='tags'>
	<li><a href="/language/python" className="tagslink"><span>Python</span></a></li>
	<li><a href="/language/javascript" className="tagslink"><span>JavaScript</span></a></li>
	<li><a href="/language/java" className="tagslink"><span>Java</span></a></li>
	<li><a href="/language/go" className="tagslink"><span>Go</span></a></li>
	<li><a href="/language/cpp" className="tagslink"><span>C++</span></a></li>
	<li><a href="/language/typescript" className="tagslink"><span>TypeScript</span></a></li>
	<li><a href="/language/php" className="tagslink"><span>PHP</span></a></li>
	<li><a href="/language/c" className="tagslink"><span>C</span></a></li>
	<li><a href="/language/ruby" className="tagslink"><span>Ruby</span></a></li>
	<li><a href="/language/csharp" className="tagslink"><span>C#</span></a></li>	
    </ul>
    </>

    )

}
export default Sidebartags;
  