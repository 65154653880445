import React from 'react';
import Star from './assets/star.svg';
import Loading from './assets/loading.gif';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTwitter, faFacebook, faLinkedin, faWhatsapp, faPinterest } from '@fortawesome/free-brands-svg-icons';
import { Link } from 'react-router-dom';



const ResultsDisplay = ({ results, loading, error, searchQuery }) => {
  const initialResults = results.slice(10, 100);

  const totalCount = results.length > 0 ? results[0].total_count : 0;
   
  return (
    <div className="container py-5 my-5" id="searchresult">
      {/* Display Search Results */}
      {loading && (
        <div className="loading">
          <img loading="lazy" src={Loading} alt="FilesGenius.com" />
          {setTimeout(() => {}, 3000)}
        </div>
      )}

      {error && <p>Error fetching search results</p>}

      {initialResults.length > 0 && (
        <div>
          <h3 className="text-center mb-5">Total <b>{totalCount}</b> Repositories Found</h3>
          <div className="row cardlist">
            {initialResults.map((result) => (
              <div key={result.id} className="col-md-3 mb-4">
                  <div className="card">
                    <div className="card-body">
                    {result.forks_count !== undefined && result.forks_count !== null && result.language !== '' && (<div className="forked"><strong><img loading="lazy" src={Star} alt="Stars" className="noborder" />  Forks </strong> {result.forks_count}</div>)}
                    
                    <Link to={`/repo/${result.login}/${result.name}`}>
                  <img loading="lazy" src={result.avatar_url} className="card-img-top" alt="Avatar" />
                  <h5 className="card-title">{result.name}</h5>
                </Link>
                    </div>
                    <ul className="list-group list-group-flush">
                      <li className="list-group-item">
                      <strong>Owner:</strong> {result.login}
                      {result.language !== undefined && result.language !== null && result.language !== '' && (<small>Language: {result.language}</small>)}
                      {result.watchers !== undefined && result.watchers !== null && result.watchers !== '' && (<small>Watchers: {result.watchers}</small>)}
                      {result.created_at !== undefined && result.created_at !== null && result.created_at !== '' && (<small>Published: {result.created_at}</small>)}
                      {result.license !== undefined && result.license !== null && result.license !== '' && (<small>License: {result.license}</small>)}
                      </li>
                      <li className="list-group-item">
                      <div className="share">
                      <a href={`https://www.facebook.com/sharer/sharer.php?u=${result.html_url}&p[title]=referral from https://www.filesgenius.com`} target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faFacebook} /></a>
                      <a href={`http://twitter.com/share?text=Check out this GitHub repo: ${result.html_url} referral from https://www.filesgenius.com`} target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faTwitter} /></a>
                      <a href={`http://www.linkedin.com/shareArticle?mini=true&url=${result.html_url}&summary=referral from https://www.filesgenius.com`} target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faLinkedin} /></a>
                      <a href={`https://api.whatsapp.com/send/?text=Check out this GitHub repo: ${result.html_url} referral from https://www.filesgenius.com`} target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faWhatsapp} /></a>
                      <a href={`http://pinterest.com/pin/create/button/?url=Check out this GitHub repo: ${result.html_url} referral from https://www.filesgenius.com`} target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faPinterest} /></a>
                      </div>
                      </li>
                    </ul>
                  </div>
                  </div>
            ))}
          </div>

          {/* Pagination (adapt as needed) */}
          {results.length > 10 &&  <div className="pagination-buttons col-md-12 text-center">
          <a href={`https://github.com/search?q=${searchQuery}&type=repositories`} target="_blank" rel="noopener noreferrer" className="btn btn-primary">View All <b className="query">{searchQuery}</b> Projects Repo</a></div>}

          
        </div>
      )}
    </div>
  );
};

    
export default ResultsDisplay;
