import React, { useRef } from 'react';
 import contactimg from './assets/repoupload.png';
import emailjs from '@emailjs/browser';

const Submitrepo = () => {
   const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_fsldbwu', 'template_u6w2hqc', form.current, 'Skuoq9yHt35HWdnHN')
      .then((result) => {
        const successMessage = document.querySelector('.success-message');
        successMessage.classList.remove('d-none'); 
        
          }, (error) => {
          console.log(error.text);
      });
  };

 
  return (

    <div className="container topspacer pb-5">
      <div className="row">
      <div className="col-md-12">
        <h3 className="text-center">Feature Your GIT Repository in the Spotlight</h3>
        <p className="text-center mb-5">Showcase your project to a global audience by submitting it to our platform. Share your innovation, connect with like-minded developers, and let your code shine in the spotlight.</p>
        </div>
      <div className="col-md-6 order-lg-2">
        <img loading="lazy" src={contactimg} alt="contact page file genius" />
      </div>
        <div className="col-md-6">
        <form ref={form} onSubmit={sendEmail} className="ctform">
      <h5>Your GIT Repository Could Be the Next Featured Star!</h5>
      <label>
        Name:
        <input type="text" name="user_name" />
      </label>
      <br />
      <label>
        Git Repo:
        <input type="url" name="url" required="required" />
      </label>
      <br />
      <label>
        Email:
        <input type="email" name="user_email" required="required" />
      </label>
      <br />
      <label>
        Message:
        <textarea name="message"   />
      </label>
      <br />
      <button type="submit" className="btn btn-primary">SUBMIT REPO</button>
            <div className="success-message d-none">
                  <br /><br />
                  Thank you for taking the time to connect with us. A representative will reach out to you shortly. We appreciate your interest and look forward to assisting you further.
                </div>
      {/*<small className="mt-4 d-block"><b>Swift Code: ALFHPKKA</b><br />
      Fuel creativity and keep the ideas brewing! Your support with a cup of coffee would be the perfect blend to kickstart a day of innovation and productivity."</small>*/}
    </form>
</div>


</div></div>

  );
};

export default Submitrepo;
