import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTwitter, faFacebook, faLinkedin, faWhatsapp, faPinterest } from '@fortawesome/free-brands-svg-icons';
import { Link } from 'react-router-dom';

import Loading from './assets/loading.gif';

function formatDate(timestamp) {
  const dateObject = new Date(timestamp);
  const options = { year: 'numeric', month: 'long', day: 'numeric' };
  return dateObject.toLocaleDateString('en-US', options);
}


const MostDownloaded = () => {
  const [latestRepos, setLatestRepos] = useState([]);
  const [downloadedRepos, setDownloadedRepos] = useState([]);
  const [loadingLatest, setLoadingLatest] = useState(true);
  const [loadingDownloaded, setLoadingDownloaded] = useState(true);

  const fetchLatestRepos = async () => {
    try {
      const response = await axios.get('https://api.github.com/search/repositories?q=created:>2022-01-01&sort=updated&order=desc');
      //const randomIndex = Math.floor(Math.random() * response.data.items.slice(0, 8));
      setLatestRepos(response.data.items.slice(0, 8));
    } catch (error) {
      console.error('Error fetching latest repos:', error);
    } finally {
      setLoadingLatest(false);
    }
  };
  
  const fetchDownloadedRepos = async () => {
    try {
      const response = await axios.get('https://api.github.com/search/repositories?q=stars:>1000&sort=updated&order=desc');
      //const randomIndex = Math.floor(Math.random() * response.data.items.slice(0, 8));
      setDownloadedRepos(response.data.items.slice(0, 8));
    } catch (error) {
      console.error('We are currently experiencing a high volume of users. Please consider waiting or refreshing in a little while. Thank you for your patience:', error);
    } finally {
      setLoadingDownloaded(false);
    }
  };
  
  
  useEffect(() => {
    fetchLatestRepos();
    fetchDownloadedRepos();
  }, []);

  const renderRepoList = (repos, loading) => (

  <div className="col-md-12">
    <div className="row">
      {loading ? (
        <div className="col-md-12 text-center mt-4">
          <img loading="lazy" src={Loading} alt="Loading..." />
          <p>Loading...</p>
        </div>
      ) : repos.length > 0 ? (
        repos.map((repo) => (
          <div key={repo.id} className="col-md-3 mb-4">
          <div className="card">
            <div className="card-body">
            <div className="forked"> <strong>Forks</strong> {repo.forks_count}</div>
            <Link to={`/repo/${repo.owner.login}/${repo.name}`}>
                  <img loading="lazy" src={repo.owner.avatar_url} className="card-img-top" alt="Avatar" />
                  <h5 className="card-title">{repo.name}</h5>
                </Link>
            </div>
            <ul className="list-group list-group-flush">
              <li className="list-group-item">
              <strong>Owner:</strong> {repo.owner.login}
              {repo.language !== undefined && repo.language !== null && repo.language !== '' && (<small>Language: {repo.language}</small>)}
              {repo.watchers !== undefined && repo.watchers !== null && repo.watchers !== '' && (<small>Watchers: {repo.watchers}</small>)}
              {repo.created_at !== undefined && repo.created_at !== null && repo.created_at !== '' && (  <small>Published: {formatDate(repo.created_at)}</small>)}
              </li>
              <li className="list-group-item">
              <div className="share">
              <a href={`https://www.facebook.com/sharer/sharer.php?u=${repo.html_url}&p[title]=referral from https://www.filesgenius.com`} target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faFacebook} /></a>
              <a href={`http://twitter.com/share?text=Check out this GitHub repo: ${repo.html_url} referral from https://www.filesgenius.com`} target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faTwitter} /></a>
              <a href={`http://www.linkedin.com/shareArticle?mini=true&url=${repo.html_url}&summary=referral from https://www.filesgenius.com`} target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faLinkedin} /></a>
              <a href={`https://api.whatsapp.com/send/?text=Check out this GitHub repo: ${repo.html_url} referral from https://www.filesgenius.com`} target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faWhatsapp} /></a>
              <a href={`http://pinterest.com/pin/create/button/?url=Check out this GitHub repo: ${repo.html_url} referral from https://www.filesgenius.com`} target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faPinterest} /></a>
              </div>
              </li>
            </ul>
          </div>
        </div>
            ))
          ) : (
            <div className="col-md-12 text-center mt-4">
              <p>Refresh the page; you might have missed an important update or new content.</p>
            </div>
          )}
        </div>
        </div>  
  );

  return (
    
    <div className="container-fluid">
      <div className="container">
        <div className="row cardlist">
        <div className="col-md-12">
        <h3 className="text-center">Explore the GitHub Gems Highly Forked Repositories</h3>
        <p className="text-center mb-5">Dive into the world of open-source collaboration, innovation, and community-driven projects.</p>
        </div>
        {renderRepoList(downloadedRepos, loadingDownloaded)}
        </div>
        <div className="row mb-5 pb-5 cardlist">
        <div className="col-md-12">
      <h3 className="text-center mt-5 pt-5">Fresh Code Unveiled: Explore the Latest Repositories</h3>
        <p className="text-center mb-5">Stay ahead of the curve by exploring these recently published repositories, curated to inspire and engage developers worldwide.</p>
      </div>
        {renderRepoList(latestRepos, loadingLatest)}
      </div>
    </div>
    </div>
  );
};

export default MostDownloaded;
