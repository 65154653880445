import React from 'react';
 

const Footer = () => {
  return (
    <footer id="site-footer" role="contentinfo">

    <div id="footer-wave"></div>

    <div className="footer-bg">

        <div className="footer-inner container-xl">

            
            <div className="footer-bottom">

                <div className="footer-credits">

                    <p className="footer-copyright">&copy; 2024 FileGenius. All rights reserved. 
                    </p>

                </div>

                <nav className="footer-menu-wrapper" aria-label="Footer" role="navigation">
                    <ul className="footer-menu">
                        <li id="menu-item-7155" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-7155"><a href="/terms">TOS</a></li>
<li id="menu-item-7152" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-7152"><a href="/disclaimer/">Disclaimer</a></li>
<li id="menu-item-7153" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-7153"><a href="/privacy/">Privacy Policy</a></li>
                    </ul>
                </nav>
            </div>
        </div>
    </div>
</footer>

  )}
export default Footer;
