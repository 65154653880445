// LanguageResultsPage.js

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';  // Import useParams
import Loading from './assets/loading.gif';
import { Link } from 'react-router-dom';
 
import Star from './assets/star.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTwitter, faFacebook, faLinkedin, faWhatsapp, faPinterest } from '@fortawesome/free-brands-svg-icons';
import Featuredlanguage from './Featuredlanguage';


const LanguageResultsPage = () => {
    const { language } = useParams();  // Get the 'language' parameter from the URL

  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchResults = async () => {
      try {
        setLoading(true);
        setError(null);

        const response = await axios.get(`https://api.github.com/search/repositories?q=language:${language}`);
        setResults(response.data.items);
      } catch (error) {
        console.error('Error fetching search results:', error);
        setError('Error fetching search results');
      } finally {
        setLoading(false);
      }
    };

    fetchResults();
  }, [language]);

  return (
    <div>
          {/* Display FeaturedRepo component before search results */}
          <Featuredlanguage language={language} />

    <div className="container py-5 my-5" id="language-results">
      {loading && (
        <div className="loading">
          <img loading="lazy" src={Loading} alt="FilesGenius.com" />
        </div>
      )}

      {error && <p>Error fetching search results</p>}

      {results.length > 0 && (
        <div>

          <h3 className="text-center mb-5">Repositories in {language}</h3>
          {/* Display results similar to ResultsDisplay */}
          <div className="row cardlist">
            {results.map((result) => (
             <div key={result.id} className="col-md-3 mb-4">
             <div className="card">
               <div className="card-body">
               {result.forks_count !== undefined && result.forks_count !== null && result.language !== '' && (<div className="forked"><strong><img loading="lazy" src={Star} alt="Stars" className="noborder" />  Forks </strong> {result.forks_count}</div>)}
             <Link to={`/repo/${result.owner.login}/${result.name}`}>
                  <img loading="lazy" src={result.owner.avatar_url} className="card-img-top" alt="Avatar" />
                  <h5 className="card-title">{result.name}</h5>
                </Link>
               </div>
               <ul className="list-group list-group-flush">
                 <li className="list-group-item">
                 <strong>Owner:</strong> {result.owner.login}
                 {result.language !== undefined && result.language !== null && result.language !== '' && (<small>Language: {result.language}</small>)}
                 {result.watchers !== undefined && result.watchers !== null && result.watchers !== '' && (<small>Watchers: {result.watchers}</small>)}
                 {result.created_at !== undefined && result.created_at !== null && result.created_at !== '' && (<small>Published: {result.created_at}</small>)}
                 </li>
                 <li className="list-group-item">
                 <div className="share">
                 <a href={`https://www.facebook.com/sharer/sharer.php?u=${result.html_url}&p[title]=referral from https://www.filesgenius.com`} target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faFacebook} /></a>
                 <a href={`http://twitter.com/share?text=Check out this GitHub repo: ${result.html_url} referral from https://www.filesgenius.com`} target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faTwitter} /></a>
                 <a href={`http://www.linkedin.com/shareArticle?mini=true&url=${result.html_url}&summary=referral from https://www.filesgenius.com`} target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faLinkedin} /></a>
                 <a href={`https://api.whatsapp.com/send/?text=Check out this GitHub repo: ${result.html_url} referral from https://www.filesgenius.com`} target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faWhatsapp} /></a>
                 <a href={`http://pinterest.com/pin/create/button/?url=Check out this GitHub repo: ${result.html_url} referral from https://www.filesgenius.com`} target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faPinterest} /></a>
                 </div>
                 </li>
               </ul>
             </div>
             </div>
       ))}
     </div>

     {/* Pagination (adapt as needed) */}
     {results.length > 10 &&  <div className="pagination-buttons col-md-12 text-center">
     <a href={`https://www.github.com/search?q=language:${language}&type=repositories`} target="_blank" rel="noopener noreferrer" className="btn btn-primary">View All {language} Project</a></div>}

      
        </div>
      )}
    </div>
    </div>
  );
};

export default LanguageResultsPage;
